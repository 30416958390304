/* Fade */

.fade {
  transition-property: opacity;
  transition-timing-function: ease-in-out;
}

.fade-entering {
  opacity: 0;
}

.fade-entered {
  opacity: 1;
}

.fade-exiting {
  opacity: 0;
}

/* Slide */

.slide {
  transition-property: transform;
  transition-timing-function: ease-in-out;
}

.slide-entering {
    transform: translate3d(0, 30px, 0);
}

.slide-entered {
  transform: translate3d(0, 0, 0);
}

.slide-exiting {
  transform: translate3d(0, 30px, 0);
}
