@media print {
  .no-print {
    display: none;
  }

  * {
    color: #000 !important;
  }

  .pa3-print {
    padding: 1rem !important;
  }

  .shadow-1, .shadow-2, .shadow-3, .shadow-4, .shadow-5 {
    box-shadow: none !important;
  }

  #root > div {
    min-width: auto !important;
    max-width: 100%;
  }

  header[role="banner"], main[role="main"], footer[role="contentinfo"] {
    margin: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
