.button[data-variant="button"] {
  background-image: linear-gradient(-179deg, #BD5709 0%, #9A480A 99%);
  border: 1px solid #9A480A;
}

.button[data-variant="button"]:hover {
  background-color: #BD5709;
  background-image: none;
}

.button:active {
  transform: translateY(1px);
  box-shadow: none;
}

.button[disabled] {
  cursor: not-allowed;
}
